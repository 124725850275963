import { AuthenticateRequest, AuthenticationService, AuthenticatorService, OAuth2Tokens } from '@afe/authentication';
import { EncryptionService } from '@afe/encryption';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ReqData } from 'src/app/models/request/req-data';
import { Result } from 'src/app/models/result/result';
import { EncryptionUtil } from 'src/app/modules/sharedComponents/encryption/encryption';
import { urlConfig } from 'src/config/url.config';
import { environment } from './../../../environments/environment';


export interface SsoTicketAuthenticateRequest extends AuthenticateRequest {
  appKey: string;
  systemCode: string;
  urlLogin: string;
  ticket: string;
  sessionTicket: string;
}

export interface SsoTicketAuthenticateResponse {
  scope: string;
  id_token: string;
  token_type: string;
  expires_in: number;
  access_token: string;
  refresh_token: string;
  session_state: string;
  'not-before-policy': string;
  refresh_expires_in: number;
}

export interface UserData {
  sigla: string;
  nome: string;
}

export interface UserData2 {
  sigla: string;
  nomec: string;
}

@Injectable({
  providedIn: 'root'
})
export class TemporarySsoTicketAuthenticatorService implements AuthenticatorService {
  private authCompleteSubject = new Subject<void>();
  private userDataSubject = new BehaviorSubject<UserData2>(null);

  constructor(private readonly httpClient: HttpClient,
    private readonly encryptionService: EncryptionService,
    private readonly authenticationService: AuthenticationService,
    private readonly encryptionUtil: EncryptionUtil) { }

  public getUserDataValue(): UserData2 {
    return this.userDataSubject.getValue();
  }

  public getUserData(): Observable<UserData2> {
    return this.userDataSubject.asObservable();
  }

  public setUserData(userData: UserData): void {
    const userData2: UserData2 = {
      sigla: userData.sigla,
      nomec: userData.nome
    };
    this.userDataSubject.next(userData2);
  }

  public authenticate(
    authenticationRequest: SsoTicketAuthenticateRequest
  ): Observable<OAuth2Tokens> {
    const { appKey, urlLogin, systemCode, ticket, sessionTicket } = authenticationRequest;

    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };

    const payload = {
      grant_type: 'password',
      scope: 'openid',
      client_id: `${environment.appkeyValida}`,
      client_secret: '@client-secret-apigee',
      username: `${environment.appkeyValida}`,
      session_ticket: sessionTicket,
      session_system: 'ecs',
      security_ticket: ticket,
      security_system: systemCode,
    };

    const payloadEncoded = new URLSearchParams(
      Object.entries(payload)
    ).toString();

    return this.httpClient
      .post<SsoTicketAuthenticateResponse>(
        urlLogin,
        payloadEncoded,
        HttpOptions
      )
      .pipe(
        map((result: SsoTicketAuthenticateResponse) => {
          return new OAuth2Tokens(
            {
              authorization: result.access_token,
              expiration: result.expires_in,
            },
            {
              authorization: result.refresh_token,
              expiration: result.refresh_expires_in,
            }
          );
        })
      );
  }

  public initiate(): void {
    this.encryptionService.changeKeys().pipe(
      switchMap(() => this.authenticationService.authenticate()),
    ).subscribe({
      next: () => {
        this.authCompleteSubject.next();
      },
      error: (error) => {
        this.authCompleteSubject.error(error);
      }
    })
  }


  public onAuthComplete(): Observable<void> {
    return this.authCompleteSubject.asObservable();
  }


  public getSsoUserData(): Observable<Result> {
    const body: ReqData = {
      action: this.encryptionUtil.encryptField("get_sso_user_data"),
      backend: this.encryptionUtil.encryptField("get_sso_user_data")
    }

    return this.httpClient
      .post<Result>(urlConfig.urlChatbar, body)
      .pipe(
        map(res => this.decryptResult(res))
      );
  };

  public decryptResult(result) {
    const decryptedRes = { ...this.encryptionUtil.decryptChatbarResult(result) };

    decryptedRes.ok = decryptedRes.ok === 'true';
    decryptedRes.code = +decryptedRes.code;
    return decryptedRes;
  }
}
