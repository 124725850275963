import { Component } from '@angular/core';
import { ConnectionService } from './modules/sharedComponents/services/connection.service';

/**
 * App Component -
 */
@Component({
  selector: 'afe-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  public isOnline = true;

  constructor(private connectionService: ConnectionService) {
    this.connectionService.getConnectionStatus().subscribe((status) => {
      if (status && !this.isOnline) {
        window.location.reload();
      }

      this.isOnline = status;
    });
  }
}
