import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DssAccordionModule, DssButtonModule, DssCardModule, DssCheckboxModule, DssContainerModule, DssDropdownModule, DssFlyoutMenuModule, DssFormFieldModule, DssIconModule, DssTypographyModule } from '@dss/components';
import { DssAlertToastModule } from '@dss/components/alert-toast';
import { DssAvatarModule } from '@dss/components/avatar';
import { DssDialogModule } from '@dss/components/dialog';
import { DssDownloadModule } from '@dss/components/download';
import { DssLoaderModule } from '@dss/components/loader';
import { DssLogoModule } from '@dss/components/logo';
import { DssRadioGroupModule } from '@dss/components/radio-group';
import { DssResultStateModule } from '@dss/components/result-state';
import { DssScrollbarModule } from '@dss/components/scrollbar';
import { DssStatusIconModule } from '@dss/components/status-icon';
import { DssTooltipModule } from '@dss/components/tooltip';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ExtractInitialsPipe } from 'src/app/util/pipes/extract-initials.pipe';
import { TranslateLoaderHelper } from '../../models/translate/translate-loader-helper';
import { Error404Component } from './error404/error404.component';
import { HeaderComponent } from './header/header.component';
import { OnlineComponent } from './online/online.component';
import { SystemMessageComponent } from './system-message/system-message.component';
import { UnauthorizedPageComponent } from './unauthorized-page/unauthorized-page.component';


@NgModule({
  declarations: [
    HeaderComponent,
    OnlineComponent,
    Error404Component,
    UnauthorizedPageComponent,
    ExtractInitialsPipe,
    SystemMessageComponent
  ],
  exports: [
    HeaderComponent,
    OnlineComponent,
    Error404Component,
    UnauthorizedPageComponent,
    DssButtonModule,
    DssScrollbarModule,
    DssContainerModule,
    DssTypographyModule,
    DssAlertToastModule,
    DssLoaderModule,
    DssFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    DssTooltipModule,
    DssResultStateModule,
    DssStatusIconModule,
    ExtractInitialsPipe,
    SystemMessageComponent
  ],
  imports: [
    NgbModule,
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateLoaderHelper.createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    FormsModule,
    ReactiveFormsModule,
    DssTypographyModule,
    DssIconModule,
    DssCardModule,
    DssAccordionModule,
    DssLoaderModule,
    DssDialogModule,
    DssAlertToastModule,
    DssCheckboxModule,
    DssButtonModule,
    DssAvatarModule,
    DssContainerModule,
    DssDownloadModule,
    DssFormFieldModule,
    DssDropdownModule,
    DssFlyoutMenuModule,
    NgbDropdownModule,
    DssScrollbarModule,
    DssTooltipModule,
    DssResultStateModule,
    DssStatusIconModule,
    DssRadioGroupModule,
    DssLogoModule
  ]
})
export class SharedModule { }
