import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';


/**
 * Routes
 * Rotas da aplicação
 * @author Edmilson Silva <ebsilva@indracompany.com>
 */
const routes: Routes = [
  // { path: 'home', component:ChatHomeComponent},
  {
    path: 'home',
    loadChildren: () => import('./modules/chat/chat.module').then(module => module.ChatModule)
  },
  {
    path: 'queue',
    loadChildren: () => import('./modules/queue/queue.module').then(module => module.QueueModule),
  },
  { path: '', redirectTo: 'home' },
  {
    path: 'nps',
    loadChildren: () => import('./modules/nps/nps.module').then(module => module.NpsModule)

  },
];

/**
 * App Routing Modules
 */
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule, TranslateModule],
})
export class AppRoutingModule { }
